<template>
  <div class="allocation">
            <div class="top-box">
              <div class="top-title">学生分配</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
                <div class="btn" @click="handleSaveClick"><i class="el-icon-document-checked"></i> 保存</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">服务类型</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="serviceType"
                      v-loading="serviceTypeListLoading"
                      element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in serviceTypeList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">学生姓名</div>
                  <div class="input-box">
                      <el-input v-model="studentName" placeholder="学生姓名" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">学生所在</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="province"
                    v-loading="provinceListLoading"
                    element-loading-spinner="el-icon-loading">
                       <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in provinceList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="5" class="form-item">
                  <div class="label">电话号码</div>
                  <div class="input-box">
                      <el-input v-model="phone" placeholder="电话号码" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="1" class="form-item">
                 <div class="action-link" @click="handlerFormCollapse()">{{formCollapse?'收起':'展开'}}</div>
              </el-col>
               <div v-if="formCollapse">
                <el-col :span="6" class="form-item">
                    <div class="label">服务老师</div>
                    <div class="input-box">
                        <el-input v-model="teacher" placeholder="服务老师" class="form-control"></el-input>
                    </div>
                </el-col>
               </div>
            </el-row>

            <!--  -->
            <div class="shuttle-container">
                <div class="table-container">
                  <div class="table-tile">未分配学生列表</div>
                  <el-table :data="studentList" 
                        height="500"
                        class="shuttle-table"
                        ref="studentList"
                        stripe 
                        :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                        v-loading="studentListLoading">
                    <el-table-column fixed type="selection" width="42"></el-table-column>
                    <el-table-column prop="name" label="学生姓名"></el-table-column>
                    <el-table-column prop="province" label="省份"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                  </el-table>
                </div>

                <div class="shuttle-btn" @click="addStudent"><i class="el-icon-arrow-right"></i></div>

                <div class="table-container">
                  <div class="table-tile">老师列表</div>
                  <el-table :data="teacherList" 
                        height="500"
                        class="shuttle-table"
                        stripe 
                        :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                        v-loading="teacherListLoading">
                    <el-table-column fixed width="30">
                        <template slot-scope="scope">
                            <el-radio v-model="selectedTeacher" :label="scope.row.id"></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="老师名称"></el-table-column>
                    <el-table-column prop="status" label="状态"></el-table-column>
                    <el-table-column prop="department" label="部门"></el-table-column>
                </el-table>
                </div>

                <div class="shuttle-btn" @click="removeStudent"><i class="el-icon-arrow-left"></i></div>

                <div class="table-container">
                  <div class="table-tile">已分配列表</div>
                  <el-table :data="getTeacherStudent()" 
                        height="500"
                        class="shuttle-table"
                        ref="teacherStudentList"
                        stripe 
                        :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                        v-loading="teacherStudentListLoading">
                    <el-table-column fixed type="selection" width="42"></el-table-column>
                    <el-table-column prop="name" label="学生姓名"></el-table-column>
                    <el-table-column prop="province" label="省份"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                  </el-table>
                </div>
            </div>
  </div>
</template>
<script>
export default {
  name: "allocation",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      formCollapse: false,
      serviceTypeListLoading:false,
      provinceListLoading:false,
      studentListLoading:false,
      teacherListLoading:false,
      teacherStudentListLoading:false,
      serviceType:"",
      province:"",
      studentName:"",
      phone:"",
      teacher:"",
      serviceTypeList:[],
      provinceList:[],
      selectedTeacher:"",
      studentList: [],
      teacherList:[]
    };
  },
  methods: {
    initSelectData(){
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.serviceTypeListLoading = true;
      //获取服务
      this.$http.post(this.$apis.getServiceList).then(r=>{
        this.serviceTypeListLoading = false;
        if (r.data) {
          this.serviceTypeList = r.data.list.map(r=>{
            return {
              key:r.id,
              label:r.serviceName,
              value:r.id
            }
          });
        }
      });
    },
    initTeacherList(){
      this.teacherListLoading = true;
      this.$http.post(this.$apis.getTeacherList).then((res) => {
        this.teacherListLoading=false;
        if (res.data && res.data.list) {
          this.teacherList = res.data.list.map(r=>{
            return {
                id:r.id,
                name:r.teacherName,
                status:r.status=="0"?"正常":"异常",
                department:r.teacherDept,
                student:[
                    {
                      id:"5",
                      name: "老五",
                      province:"天津",
                      phone:"15500005555",
                    },
                    {
                      id:"6",
                      name: "老六",
                      province:"北京",
                      phone:"15500005555",
                    },
                ]
            }
          });
          this.selectedTeacher = this.teacherList[0].id;
        }
      });
    },
    initStudentList(){
      this.studentListLoading = true;
      var data={
          notMapping: true,
          phone: this.phone,
          province: this.province,
          serviceId: this.serviceType,
          studentName: this.studentName
          //todo 缺少服务老师
      };
      this.$http.post(this.$apis.getStuderntByPage,data).then((res) => {
        this.studentListLoading=false;
        if (res.data && res.data.list) {
          this.studentList = res.data.list.map(r=>{
            return {
              id:r.studentId,
              name: r.studentName,
              province:r.province,
              phone:r.phone,
            }
          });
        }
      });
    },
    handlerFormCollapse(){
      this.formCollapse = !this.formCollapse;
    },
    addStudent(){
      let selectedStudent = this.$refs.studentList.selection;
      if(selectedStudent.length < 0){
          return null;
      }
      this.teacherList.forEach(r=>{
          if(r.id===this.selectedTeacher){
              selectedStudent.map(s=>{
                  r.student.push(s);
                  this.studentList.splice(this.studentList.indexOf(s),1);
              });
          }
      });
    },
    removeStudent(){
        let selectedStudent = this.$refs.teacherStudentList.selection;
        if(selectedStudent.length < 0){
            return null;
        }

        this.teacherList.forEach(r=>{
            if(r.id===this.selectedTeacher){
                selectedStudent.map(s=>{
                    r.student.splice(r.student.indexOf(s),1);
                    this.studentList.push(s);
                });
            }
        });
    },
    getTeacherStudent(){
      //this.teacherStudentListLoading = true;
      const result = [];
      this.teacherList.filter(r=>r.id===this.selectedTeacher).map(item=>{
          item.student.map(s=>{
              result.push(s);
          })
      });
      //this.teacherStudentListLoading = false;
      return result;
    },
    handleSearchClick(){
      this.initTeacherList();
      this.initStudentList();
    },
    handleSaveClick(){

    }
  },
  mounted(){
    this.initSelectData();
    this.initTeacherList();
    this.initStudentList();
  },
};
</script>

<style lang="less" scoped>
.allocation {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
        height: 55px;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 14px;
          cursor:pointer;
          color: #ff450b;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      border: 1px solid #e6e6e6;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  .shuttle-container {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .table-container{
      width: 30%;
      .table-tile{
        font-size: 14px;
        font-weight: 900;
        color: #646464;
      }
    }
    .shuttle-table{
        height: 600px;
        width: 100%;
        border:1px solid #e6e6e6;
        .el-radio__label{
            display: none;
        }
    }
    .shuttle-btn{
        margin: 0 30px;
        line-height: 25px;
        width: 25px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        i{
            cursor: pointer;
        }
    }
  }
}
</style>